import { useModal } from 'vue-final-modal';
import { defineAsyncComponent } from '#imports';

export const useInfoPopup = () => {
  const openInfoPopup = ({ heading, title, description, buttonData }) => {
    const { open, close } = useModal({
      modalId: 'infoPopup',
      component: defineAsyncComponent(
        () => import('@/components/popups/Info.vue'),
      ),
      attrs: {
        closeModal: () => {
          close();
        },
        heading: heading,
        title: title,
        description: description,
        buttonData: buttonData,
      },
    });

    open();
  };

  return {
    openInfoPopup,
  };
};
