import { defineStore } from 'pinia';
import { useCookie } from 'nuxt/app';
import { useRequest } from '@/composables/useRequest';
import { useUserStore } from '@/stores/user';
import { USER_ROLES } from '@/configs/user-roles';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    accessToken: null,
    phoneVerificationUUID: null,
    tokenStartLifeTime: null,
  }),
  getters: {
    isAuthorized: (state) => {
      const userStore = useUserStore();

      return !!state.accessToken && userStore.user?.role === USER_ROLES.ACTIVE;
    },
  },
  actions: {
    checkRegisterStatus() {
      return useRequest('/auth/register/step/status', {
        method: 'GET',
      }).then(({ data }) => {
        return data;
      });
    },
    checkPhoneCallVerification() {
      if (!this.phoneVerificationUUID) return;

      return useRequest(
        `auth/register/step/phone/verify/call/${this.phoneVerificationUUID}`,
        {
          method: 'GET',
        },
      ).then(({ data }) => {
        console.log('checkPhoneCallVerification', data);

        return data;
      });
    },
    async sendPhoneVerification({ code } = {}) {
      if (!this.phoneVerificationUUID) return;

      return useRequest('/auth/register/step/phone/verify/code', {
        method: 'POST',
        body: {
          uuid: this.phoneVerificationUUID,
          code,
        },
      }).then(({ data }) => {
        console.log('sendPhoneVerification', data);

        return data;
      });
    },
    sendPhoneVerificationRequest() {
      return useRequest('/auth/register/step/phone/verify', {
        method: 'POST',
      }).then(({ data }) => {
        this.phoneVerificationUUID = data.uuid;
        console.log('sendPhoneVerificationRequest', data);

        return data;
      });
    },
    changePhone(mobilePhone = '') {
      return useRequest('/auth/register/step/phone', {
        method: 'POST',
        body: {
          mobilePhone,
        },
      }).then(({ data }) => {
        this.phoneVerificationUUID = data.uuid;

        return data;
      });
    },
    changeEmailVerification({ email }) {
      return useRequest('/auth/register/step/email', {
        method: 'POST',
        body: {
          email,
        },
      }).then(({ data }) => {
        console.log('changeEmailVerification', data);

        return data;
      });
    },
    sendEmailVerificationRequest() {
      return useRequest('/auth/register/step/email/verify', {
        method: 'POST',
      }).then(({ data }) => {
        console.log('sendEmailVerificationRequest', data);

        return data;
      });
    },
    setRegistrationPassword({ password, password_confirmation }) {
      return useRequest('/auth/register/step/password', {
        method: 'POST',
        body: {
          password,
          password_confirmation,
        },
      }).then(({ data }) => {
        console.log('setRegistrationPassword', data);

        return data;
      });
    },
    sendRegistration(data = {}) {
      const mindBoxDeviceUuidCookie = useCookie('mindboxDeviceUUID');
      const userStore = useUserStore();

      return useRequest('/auth/register/step/personal-data', {
        method: 'POST',
        body: {
          lastName: data.last_name,
          firstName: data.first_name,
          middleName: data.middle_name,
          birthDate: data.birthdate,
          mobilePhone: data.mobilePhone,
          email: data.email,
          sex: data.gender.value,
          policy: data.is_agree,
          newsletter: data.is_receive_newsletter,
          mindboxDeviceUuid: mindBoxDeviceUuidCookie.value,
          captcha: data.captcha,
        },
      }).then(({ data }) => {
        this.setTokenData(data.access_token);

        return userStore.fetchUser();
      });
    },
    sendPassportData(formData) {
      return useRequest('/auth/register/step/passport', {
        method: 'POST',
        body: formData,
      }).then(({ data }) => {
        console.log('sendPassportData', data);

        return data;
      });
    },
    sendLoginByTicket({ ticket } = {}) {
      const userStore = useUserStore();

      return useRequest('/auth/login/ticket', {
        method: 'POST',
        body: {
          authenticationTicket: ticket,
        },
      }).then(({ data }) => {
        this.setTokenData(data.access_token);

        return userStore.fetchUser();
      });
    },
    sendLogin(data = {}) {
      const mindBoxDeviceUuidCookie = useCookie('mindboxDeviceUUID');

      return useRequest('/auth/login/phone', {
        method: 'POST',
        body: {
          phone: data.phone,
          password: data.password,
          mindboxDeviceUuid: mindBoxDeviceUuidCookie.value,
          captcha: data.captcha,
        },
      }).then(({ data }) => {
        this.setTokenData(data.access_token);
      });
    },
    refreshToken() {
      const userStore = useUserStore();
      return useRequest('/auth/refresh', {
        method: 'POST',
      }).then(({ data }) => {
        this.setTokenData(data.access_token);

        userStore.fetchUser();
      });
    },
    sendLogout() {
      return useRequest('/auth/logout', {
        method: 'POST',
      }).then(() => {
        this.logout();
      });
    },
    logout() {
      const userStore = useUserStore();

      this.clearTokenData();
      userStore.clearUserData();
    },
    initTokens() {
      const tokenCookie = useCookie('token');

      if (tokenCookie.value) {
        this.setTokenData(tokenCookie.value);
      }
    },
    setTokenData(token) {
      this.accessToken = token;

      const tokenCookie = useCookie('token');

      if (tokenCookie.value !== this.accessToken) {
        tokenCookie.value = this.accessToken;
      }
    },
    clearTokenData() {
      this.accessToken = null;

      const tokenCookie = useCookie('token');
      tokenCookie.value = null;
    },
    fetchServerTime() {
      return useRequest('/time', {
        method: 'GET',
      }).then(({ data }) => {
        return data;
      });
    },
  },
});
